import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login&Logout/Login";
import Logout from "./pages/Login&Logout/Logout";
import Register from "./pages/Login&Logout/Register";
import DrawFuc from "./pages/fuction/DrawFuc";
import ImgFuc from "./pages/fuction/ImgFuc";
import TalkFuc from "./pages/fuction/TalkFuc";

function AppRoute() {
  return (
    <Routes>
      <Route path="/" element={<Logout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/talk" element={<TalkFuc />} />
      <Route path="/img" element={<ImgFuc />} />
      <Route path="/draw" element={<DrawFuc />} />
    </Routes>
  );
}

export default AppRoute;
