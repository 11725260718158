import Button from "antd/es/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Draw from "../../components/draw/draw";
import AiStructrue from "../../layouts/AiStructrue";

const TalkFuc = () => {
  const [talkWait, setTalkWait] = useState("");
  const navigate = useNavigate();
  const backClick = () => {
    navigate("/login");
  };
  return (
    <AiStructrue
      title={"画图"}
      rightTopDiv={
        <Button size="small" ghost onClick={() => backClick()}>
          返回
        </Button>
      }
      waitT={talkWait}>
      <Draw />
    </AiStructrue>
  );
};

export default TalkFuc;
