import { Image, Modal, message } from "antd";
import { forwardRef, useEffect } from "react";
import { prompsts } from "../../utils/prompts_zh";
import { allModelsMap, copyText, markDownCode } from "../../utils/util";
import "./components.scss";
export const HumanBlock = (props: { value: string }) => {
  return (
    <div className="humanBlock">
      <Image className="humanHead" preview={false} src={require("../../assets/home_icon_my.png")} />
      <div className="humanContext">
        {props.value}
        <span className="copy" onClick={() => copyText(props.value)}>
          复制
        </span>
      </div>
    </div>
  );
};
export const AiBlock = forwardRef((props: { value: string }, ref: any) => {
  return (
    <div className="aiBlock" ref={ref}>
      <Image className="aiHead" preview={false} src={require("../../assets/home_icon_my2.png")} />
      <div className="aiContext">
        {typeof props.value === "string" ? markDownCode(props.value) : props.value}
        <span className="copy" onClick={() => copyText(props.value)}>
          复制
        </span>
      </div>
    </div>
  );
});
//角色扮演弹窗
export const RolePlay = (props: { createCreative: any; setCurInput: any; setOpen: any; open: any }) => {
  const clickPrompt = (prompst: { prompt: string; act: string }) => {
    props.setCurInput(prompst.prompt);
    message.success(`角色设置为${prompst.act}`, 1.5);
    props.setOpen(false);
  };
  useEffect(() => {}, []);
  return (
    <Modal title="角色扮演" getContainer={props.createCreative.current} centered footer={null} open={props.open} onCancel={() => props.setOpen(false)} width={1000}>
      <div className="ModalBody">
        {prompsts.map((prompst: any) => (
          <div className="promptChoice" key={prompst.act} onClick={() => clickPrompt(prompst)}>
            <h4 className="promptName">{prompst.act}</h4>
            <p className="promptDetail oneLine">{prompst.prompt}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
//模型选择弹窗
export const ModelSelect = (props: { createCreative: any; setCurModel: any; setOpen: any; open: any }) => {
  const clickPrompt = (ModelName: string) => {
    props.setCurModel(ModelName);
    message.success(`模型设置为${ModelName}`, 1);
    props.setOpen(false);
  };
  useEffect(() => {}, []);
  return (
    <Modal title="模型选择" getContainer={props.createCreative.current} centered footer={null} open={props.open} onCancel={() => props.setOpen(false)} width={1000}>
      <div className="ModalBody">
        {Object.keys(allModelsMap).map((modelName: string) => (
          <div className="promptChoice" key={modelName} onClick={() => clickPrompt(modelName)}>
            <h4 className="promptName">{modelName}</h4>
            <p className="promptDetail">{allModelsMap[modelName].production}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
