import { Image } from "antd";
import "./components.scss";
export const HumanBlock = (props: { value: string }) => {
  return (
    <div className="humanBlock">
      <Image className="humanHead" preview={false} src={require("../../assets/home_icon_my.png")} />
      <div className="humanContext">{props.value}</div>
    </div>
  );
};
export const AiImgBlock = (props: { value: string }) => {
  return (
    <div className="aiBlock">
      <Image className="aiHead" preview={false} src={require("../../assets/home_icon_my2.png")} />
      {props.value.includes("错") ? <div className="aiContext">{props.value}</div> : <Image className="aiContext" src={props.value}></Image>}
    </div>
  );
};
