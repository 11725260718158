import axios from "axios";
import { Buffer } from "buffer";
import { throttle } from "lodash";
import { globalData } from "./gloabalData";
import { allModelsMap, ctlTotalLen } from "./util";
const baseData = {
  model: "gpt-3.5-turbo-16k",
  stream: true,
  temperature: 0.7,
  max_tokens: 4000,
  messages: [
    {
      role: "system",
      content: "You are a helpful assistant",
    },
  ],
};
const defaultData = [
  {
    content: "你好",
    role: "user",
  },
];
//cloudflare方式chat stream
export const cloudflareGptTalkApi = async (datas: any = defaultData, setCurAiBlock: any, curModel: string) => {
  let curData = JSON.parse(JSON.stringify(baseData));
  curData.max_tokens = allModelsMap[curModel].max_tokens; //设置单个返回值长度
  curData.model = curModel; //设置模型
  let newDatas = ctlTotalLen(datas, allModelsMap[curModel].tokenLen); //控制对话总长度
  newDatas.forEach((newData: any) => curData.messages.push(newData));
  let lastData: any = "";
  try {
    await axios({
      method: "post",
      url: `${globalData.gptchatByProxyUrl}`,
      responseType: "stream",
      data: JSON.stringify(curData),
      headers: {
        Authorization: `Bearer ${globalData.dygptKey}`,
        "content-type": "application/json",
      },
      onDownloadProgress: throttle(
        (event: { target: { responseText: string } }) => {
          if (event.target.responseText.includes('"error"')) {
            lastData = JSON.parse(event.target.responseText.replace("\nEOF", "")).error.message;
          } else {
            let arr = event.target.responseText.split("\n\ndata: ");
            if (arr[arr.length - 1].includes("[DONE]")) {
              arr.splice(-1);
              let newArr = arr.map((e: any, i: number) => (i === 0 ? JSON.parse(e.replace("data: ", "")) : JSON.parse(e)));
              lastData = newArr.map((e: any) => e.choices[0].delta.content).join("");
            } else {
              let arr2 = arr.map((e: any, i: number) => (i === 0 ? JSON.parse(e.replace("data: ", "")) : JSON.parse(e)));
              let curtext = arr2.map((e: any) => e.choices[0].delta.content).join("");
              lastData = curtext;
              setCurAiBlock(lastData);
            }
          }
        },
        100,
        { trailing: true }
      ),
    });
  } catch (e) {
    lastData = `未知错误:${e}`;
  }
  return lastData;
};
//cloudflare方式img
export const cloudflareGptImgApi = async (datas: string) => {
  let curData = {
    prompt: datas,
    n: 1,
    size: "1024x1024",
  };
  let result: any = await axios.post(`${globalData.gptimgByProxyUrl}`, JSON.stringify(curData), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${globalData.dygptKey}`,
    },
  });
  let formatResult = result.data;
  return formatResult;
};
//zby服务器方式chat stream
export const gptTalkApi = async (datas: any = defaultData, setCurAiBlock: any, curModel: string) => {
  let curData = JSON.parse(JSON.stringify(baseData));
  curData.max_tokens = allModelsMap[curModel].max_tokens; //设置单个返回值长度
  curData.model = curModel; //设置模型
  let newDatas = ctlTotalLen(datas, allModelsMap[curModel].tokenLen); //控制对话总长度
  newDatas.forEach((newData: any) => curData.messages.push(newData));
  let zbyData = {
    url: globalData.gptchatbUrl,
    method: "post",
    body_type: "json",
    response_type: "stream",
    header_fields: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${globalData.dygptKey}`,
    },
    param_fields: Buffer.from(JSON.stringify(curData)).toString("base64"),
  };
  let lastData: any = "";
  try {
    await axios({
      method: "post",
      url: `${globalData.zbyUrlNew}`,
      responseType: "stream",
      data: zbyData,
      onDownloadProgress: (event: { target: { responseText: string } }) => {
        if (event.target.responseText.includes('"error"')) {
          lastData = JSON.parse(event.target.responseText.replace("\nEOF", "")).error.message;
        } else {
          let arr = event.target.responseText.split("\n\ndata: ");
          if (arr[arr.length - 1].includes("[DONE]")) {
            arr.splice(-1);
            let newArr = arr.map((e: any, i: number) => (i === 0 ? JSON.parse(e.replace("data: ", "")) : JSON.parse(e)));
            lastData = newArr.map((e: any) => e.choices[0].delta.content).join("");
          } else {
            let arr2 = arr.map((e: any, i: number) => (i === 0 ? JSON.parse(e.replace("data: ", "")) : JSON.parse(e)));
            let curtext = arr2.map((e: any) => e.choices[0].delta.content).join("");
            lastData = curtext;
            setCurAiBlock(lastData);
          }
        }
      },
    });
  } catch (e) {
    lastData = `未知错误:${e}`;
  }
  return lastData;
};
//zby服务器方式img
export const gptImgApi = async (datas: string) => {
  let curData = {
    prompt: datas,
    n: 1,
    size: "1024x1024",
  };
  let zbyData = {
    url: globalData.gptimgbUrl,
    method: "post",
    body_type: "json",
    response_type: "json",
    header_fields: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${globalData.dygptKey}`,
    },
    param_fields: Buffer.from(JSON.stringify(curData)).toString("base64"),
  };
  let result: any = await axios.post(`${globalData.zbyUrlNew}`, zbyData);
  let formatResult = JSON.parse(result.data.data);
  return formatResult;
};
