import Button from "antd/es/button"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Talk from "../../components/talk/talk"
import AiStructrue from "../../layouts/AiStructrue"

const TalkFuc = () => {
  const [talkWait, setTalkWait] = useState("")
  const navigate = useNavigate()
  const backClick = () => {
    navigate("/login")
  }
  return (
    <AiStructrue
      title={"魔语"}
      rightTopDiv={
        <Button size="small" ghost onClick={() => backClick()}>
          返回
        </Button>
      }
      waitT={talkWait}>
      <Talk setTalkWait={setTalkWait}></Talk>
    </AiStructrue>
  )
}

export default TalkFuc
