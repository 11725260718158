import { Layout, Spin } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import './AiStructrue.scss';
const { Header, Content, Footer } = Layout;
const AiStructrue: React.FunctionComponent<Props> = (props: any) => {
  const [waitText, setWaitText] = useState('');
  useEffect(() => {
    setWaitText(props.waitT);
  }, [props.waitT]);
  const filingsClick = () => {
    const w: any = window.open('备案查询官网');
    w.location.href = 'https://beian.miit.gov.cn/';
  };
  return (
    <Layout className="layout">
      <Header className="header">
        <div className="headerTitle">
          <h1 className="headerTitleH1">{props.title}</h1>
          <div className="headerTitleP">
            {waitText} {waitText?.length >= 1 && <Spin size="small"></Spin>}
          </div>
        </div>
        <div className="rightTop">{props.rightTopDiv ?? ''}</div>
      </Header>
      <Content className="content expand" style={{}}>
        {props.children}
      </Content>
      <Footer className="Footer">
        <h3 className="headerContent">
          <div>
            copyright by:DY {props.quickLoad ?? 'ZBY'} <a onClick={filingsClick}>湘ICP备2023015960号-1</a>
          </div>
        </h3>
      </Footer>
    </Layout>
  );
};
interface Props {
  style?: CSSProperties;
  className?: string;
  rightTopDiv?: React.ReactNode;
  waitT?: string;
  quickLoad?: React.ReactNode;
  title: string;
  children: React.ReactNode;
}
export default AiStructrue;
