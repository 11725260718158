export const globalData = {
  CDNurl: "http://qnydy.softai.top/",
  dygptKey: "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",
  gptchatByProxyUrl: "https://vercel.softai.love/proxy/api.openai.com/v1/chat/completions",
  gptimgByProxyUrl: "https://vercel.softai.love/proxy/api.openai.com/v1/images/generations",
  gptchatbUrl: "aHR0cHM6Ly9hcGkub3BlbmFpLmNvbS92MS9jaGF0L2NvbXBsZXRpb25z",
  gptimgbUrl: "aHR0cHM6Ly9hcGkub3BlbmFpLmNvbS92MS9pbWFnZXMvZ2VuZXJhdGlvbnM=",
  zbyUrlpost: "/proxy/post",
  zbyUrlpost_stream: "/proxy/post_stream",
  zbyUrlNew: "/api/v1/proxy/http",
};
