import { Button, Input, message } from "antd"
import { useEffect, useRef, useState } from "react"
import { cloudflareGptImgApi, gptImgApi } from "../../utils/api"
import { AiImgBlock, HumanBlock } from "./components"
import "./index.scss"

export default function Img(props: { setImgWait: any }) {
  const [curInput, setCurInput] = useState<string>("")
  const [talkBlocks, setTalkBlocks] = useState<any[]>([])
  const [requireType, setRequireType] = useState<boolean>(true) //是否用服务器
  const messagesEnd = useRef<any>(null)

  //提交内容
  const handleSubmit = async (e: any) => {
    if (e) {
      setCurInput("") //清空内容
      let beforeBlocks = talkBlocks.map((item) => item)
      beforeBlocks.push({ type: "Human", value: e })
      setTalkBlocks(beforeBlocks)
      scrollToBottom()
    } else {
      message.info("请输入内容", 1)
    }
  }
  useEffect(() => {
    if (talkBlocks?.slice(-1)?.[0]?.type === "Human") {
      handleApi(talkBlocks)
    }
  }, [talkBlocks])
  //访问api
  const handleApi = async (talkBlocks: any) => {
    let standardMessage = talkBlocks[talkBlocks.length - 1].value
    props.setImgWait("zby的土豆服务器拼命加载中")
    let apiResult = await (requireType ? gptImgApi(standardMessage) : cloudflareGptImgApi(standardMessage))
    let beforeBlocks = talkBlocks.map((e: any) => e)
    if (apiResult?.data) {
      apiResult.data.forEach((item: any) => {
        beforeBlocks.push({ type: "Ai", value: item.url })
      })
    } else if (apiResult?.error) {
      beforeBlocks.push({ type: "Ai", value: `不好意思，出错了：${apiResult.error.message}` })
    } else {
      beforeBlocks.push({ type: "Ai", value: `不好意思，未知错误：${apiResult}` })
    }

    props.setImgWait("")
    setTalkBlocks(beforeBlocks)
    scrollToBottom()
  }
  //翻到底部
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEnd.current.scrollIntoView({ behavior: "smooth" })
    }, 100)
  }
  return (
    <div className="contain">
      <div className="mainTalk">
        {talkBlocks.map((anBlock) => {
          if (anBlock.type === "Human") {
            return <HumanBlock value={anBlock.value} />
          }
          if (anBlock.type === "Ai") {
            return <AiImgBlock value={anBlock.value} />
          }
        })}
        <div style={{ height: "1px" }} ref={messagesEnd}></div>
      </div>
      <div className="submitBar">
        <div className="subTools">
          <img
            className="Icon"
            title="切换模式"
            onClick={() => {
              message.success(requireType ? "切换为反代模式" : "切换为服务器模式", 1)
              setRequireType((v) => !!!v)
            }}
            src={require(requireType ? "../../assets/服务器.png" : "../../assets/飞行.png")}
          />
        </div>
        <Input
          className="subInput"
          placeholder="请输入提示词~"
          confirm-type="send"
          onInput={(e: any) => {
            setCurInput(e.target.value)
          }}
          value={curInput}
          onPressEnter={() => handleSubmit(curInput)}></Input>
        <Button className="subButton" type="primary" onClick={() => handleSubmit(curInput)}>
          发送
        </Button>
      </div>
    </div>
  )
}
