import Button from "antd/es/button"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Img from "../../components/img/img"
import AiStructrue from "../../layouts/AiStructrue"

const ImgFuc = () => {
  const [imgWait, setImgWait] = useState("")
  const navigate = useNavigate()
  const backClick = () => {
    navigate("/login")
  }
  return (
    <AiStructrue
      title={"魔法图"}
      rightTopDiv={
        <Button size="small" ghost onClick={() => backClick()}>
          返回
        </Button>
      }
      waitT={imgWait}>
      <Img setImgWait={setImgWait}></Img>
    </AiStructrue>
  )
}

export default ImgFuc
