import { Button } from "antd";
import React from "react";
import { isMobile, resizeBase64Img } from "../../utils/util";
import "./draw.scss";
export default class CanvasComponent extends React.Component {
  canvasRef: any;
  canvasDiv: any;
  paintColors = ["#000000", "#999999", "#CC66FF", "#FF0000", "#FF9900", "#FFFF00"];
  paintWidths = [1, 2, 4, 8, 16, 32];
  canvasContext: any; // 颜色面板
  paintColorPanel = this.paintColors.map((element, index) => {
    return (
      <span
        className={"bk"}
        style={{ backgroundColor: element }}
        onClick={() => {
          this.onColorSpanClick(index);
        }}
      />
    );
  }); // 画笔宽度面板
  paintWidthPanel = this.paintWidths.map((element, index) => {
    return (
      <span
        className={"bk"}
        onClick={() => {
          this.onWidthSpanClick(index);
        }}>
        {element}
      </span>
    );
  });
  state = {
    MobileName: null as any,
  };
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.canvasRef = React.createRef();
    this.canvasDiv = React.createRef();
  }
  /*** 选择画笔颜色* @param index*/
  onColorSpanClick = (index: number) => {
    this.canvasContext.globalCompositeOperation = "source-over";
    this.canvasContext.strokeStyle = this.paintColors[index];
  };
  /*** 选择画笔宽度* @param index*/
  onWidthSpanClick = (index: number) => {
    this.canvasContext.lineWidth = this.paintWidths[index];
  };
  /*** 初始化画笔和事件监听*/
  initPainter = () => {
    let canvas = this.canvasRef.current;
    const contentWidth = this.canvasDiv.current.clientWidth;
    const clientHeight = this.canvasDiv.current.clientHeight;
    canvas.width = contentWidth;
    canvas.height = clientHeight;
    this.canvasContext = canvas.getContext("2d");
    let x = 0;
    let y = 0;
    this.canvasContext.lineWidth = 2;
    this.canvasContext.strokeStyle = "#000000"; //鼠标按下事件

    this.state.MobileName
      ? canvas.addEventListener("touchstart", (e: TouchEvent) => {
          e.preventDefault();
          let imgTop = this.canvasRef.current.getBoundingClientRect().top;
          let imgLeft = this.canvasRef.current.getBoundingClientRect().left;
          let offsetX = e.changedTouches[0].pageX - imgLeft;
          let offsetY = e.changedTouches[0].pageY - imgTop;
          x = offsetX;
          y = offsetY;
          this.canvasContext.beginPath();
          this.canvasContext.moveTo(x, y); //鼠标移动事件
          let onTouchMoveEvent = (e: TouchEvent) => {
            let imgTop = this.canvasRef.current.getBoundingClientRect().top;
            let imgLeft = this.canvasRef.current.getBoundingClientRect().left;
            let offsetX = e.changedTouches[0].pageX - imgLeft;
            let offsetY = e.changedTouches[0].pageY - imgTop;
            let newX = offsetX;
            let newY = offsetY;
            this.canvasContext.lineTo(newX, newY);
            this.canvasContext.stroke();
            x = newX;
            y = newY;
          };
          canvas.addEventListener("touchmove", onTouchMoveEvent);
          canvas.addEventListener("touchend", () => {
            canvas.removeEventListener("touchmove", onTouchMoveEvent);
          });
        })
      : canvas.addEventListener("mousedown", (e: MouseEvent) => {
          e.preventDefault();
          x = e.offsetX;
          y = e.offsetY;
          this.canvasContext.beginPath();
          this.canvasContext.moveTo(x, y); //鼠标移动事件
          let onMouseMoveEvent = (e: MouseEvent) => {
            let newX = e.offsetX;
            let newY = e.offsetY;
            this.canvasContext.lineTo(newX, newY);
            this.canvasContext.stroke();
            x = newX;
            y = newY;
          };
          canvas.addEventListener("mousemove", onMouseMoveEvent);
          canvas.addEventListener("mouseup", () => {
            canvas.removeEventListener("mousemove", onMouseMoveEvent);
          });
        });
  };

  componentWillMount() {
    console.log("componentWillMount");
    this.setState({ MobileName: isMobile() });
  }
  componentDidMount() {
    console.log("componentDidMount");
    window.addEventListener("resize", () => {
      this.setState({ MobileName: isMobile() });
      this.initPainter();
    });
    this.initPainter();
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
    window.removeEventListener("resize", () => {
      this.setState({ MobileName: isMobile() });
    });
  }
  render() {
    return (
      <div id={"drawContainer"}>
        <div id={"bkHead"}>画笔颜色:</div>
        <div id={"bk"}>{this.paintColorPanel}</div>
        <div id={"bkHead"}>画笔宽度:</div>
        <div id={"bk"}>{this.paintWidthPanel}</div>
        <Button
          id={"outPut"}
          onClick={async () => {
            this.canvasContext.globalCompositeOperation = "destination-out";
          }}>
          橡皮擦
        </Button>
        <div id={"cav"} ref={this.canvasDiv} style={this.state.MobileName ? { height: "90vw", width: "90vw" } : { height: "calc(89vh - 200px)", width: "calc(89vh - 200px)" }}>
          <canvas ref={this.canvasRef} />
        </div>
        <Button
          id={"outPut"}
          onClick={async () => {
            let imgSrc = this.canvasRef.current.toDataURL("image/png", 1);
            let imgGoodSrc: any = await resizeBase64Img(imgSrc, 1024, 1024);
            let a = document.createElement("a"); // 生成一个a元素
            let event = new MouseEvent("click"); // 创建一个单击事件
            a.download = "绘画.png"; // 设置图片名称
            a.href = imgGoodSrc; // 将生成的imgDataSrc设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          }}>
          导出
        </Button>
      </div>
    );
  }
}
