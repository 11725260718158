import { Button, Form, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as UUID } from "uuid";
import AiStructrue from "../../layouts/AiStructrue";
import "./index.scss";
let ZBYcount = 0;
const Logout = () => {
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  const accountRef = useRef<any>();
  const passwordRef = useRef<any>();
  const handleSubmit = (e: any) => {
    let userdata = {
      Nickname: "暗世尘",
      HeadImgID: "",
      PersonalSign: "",
      Phone: "15850678199",
      Mail: "244317839@qq.com",
    };
    let nanoid = UUID();
    console.log("nanoid", nanoid);
    console.log("userdata", userdata);
    e.preventDefault();
    const account = accountRef.current.input.value;
    const password = passwordRef.current.input.value;
    //使用接口验证数据
    // axios({
    //   method: "GET",
    //   url: `https://localhost/user/${account}`,
    // }).then((response) => {
    //   if (response.data.password === password) {
    //     console.log("登录成功");
    //     setLogin(true);
    //   }
    // });
    //本地验证数据
    if (account === "admin" && password === "123456") {
      message.success("登录成功", 1);
      console.log("登录成功");
      setLogin(true);
    } else {
      message.info("账号或密码错误", 1);
      console.log("用户名或密码错误");
      console.log("account", account);
      console.log("password", password);
    }
  };
  const handleRegister = () => {
    navigate("/register");
  };
  useEffect(() => {
    if (login) {
      console.log("登录成功,正在跳转界面");
      navigate("/login");
    } else {
      console.log("等待用户登录");
    }
  });

  return (
    <AiStructrue
      title={"登录"}
      quickLoad={
        <span
          onClick={() => {
            ZBYcount++;
            if (ZBYcount > 5) {
              message.success("登录成功", 1);
              navigate("/login");
            }
          }}>
          ZBY
        </span>
      }>
      <div className="formBody">
        <Form className="Form" name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{ remember: true }} autoComplete="off">
          <Form.Item label="账号" name="account" rules={[{ required: true, message: "Please input your account!" }]}>
            <Input className="InputPlace" ref={accountRef} placeholder="account" />
          </Form.Item>
          <Form.Item label="密码" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
            <Input className="InputPlace" ref={passwordRef} type="password" placeholder="password" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16 }} className="registerButtons">
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              登录
            </Button>
            <Button className="registerButton" type="default" onClick={handleRegister}>
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AiStructrue>
  );
};

export default Logout;
