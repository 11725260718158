import Button from "antd/es/button";
import { useNavigate } from "react-router-dom";
import AiStructrue from "../../layouts/AiStructrue";

const Login = () => {
  const navigate = useNavigate();
  return (
    <AiStructrue
      title={"魔法屋"}
      rightTopDiv={
        <Button size="small" ghost onClick={() => navigate("/")}>
          退出登录
        </Button>
      }>
      <div className="mainbody">
        <Button className="talkButton ButtonSize" onClick={() => navigate("/talk")}>
          魔语
        </Button>
        <Button className="imgButton ButtonSize" onClick={() => navigate("/img")}>
          魔图
        </Button>
        <Button className="drawButton ButtonSize" onClick={() => navigate("/draw")}>
          画图
        </Button>
      </div>
    </AiStructrue>
  );
};

export default Login;
