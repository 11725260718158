import { message } from "antd";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
//生成代码块
export const markDownCode = (text: string) => {
  let resultArray = text.split("```");
  let codeNum = Math.floor(resultArray.length / 2);
  let newresult: any = [resultArray[0]];
  for (let i = 1; i <= codeNum; i++) {
    let magicCode = resultArray[2 * i - 1];
    newresult.push(
      <ReactMarkdown
        children={"```" + magicCode}
        components={{
          code({ node, className, children }): any {
            const match = /language-(\w+)/.exec(className || "");
            let aa: any = <SyntaxHighlighter children={String(children).replace(/\n$/, "")} language={match?.[1] ?? "javascript"} style={atomDark as any} PreTag="div" />;
            return aa;
          },
        }}
      />
    );
    newresult.push(resultArray[2 * i]);
  }
  let newDom = (
    <div>
      {newresult.map((e: any, i: number) => (
        <div style={{ background: i % 2 === 1 ? "black" : "white" }}>{e}</div>
      ))}
    </div>
  );
  return newDom;
};

//控制对话总长
export const ctlTotalLen = (datas: any, tokenLen: number) => {
  let totalcount = 0;
  let newDatas = [];
  for (let i = datas.length - 1; i >= 0; i--) {
    totalcount += JSON.stringify(datas[i]).length;
    if (totalcount < tokenLen) {
      //控制为3000字内
      newDatas.push(datas[i]);
    } else {
      break;
    }
  }
  newDatas = newDatas.reverse();
  return newDatas;
};
export const allModelsMap: any = {
  "gpt-3.5-turbo": {
    max_tokens: 1000,
    tokenLen: 2000,
    production:
      "GPT-3.5-Turbo是GPT-3.5系列中的最高阶模型。它是在GPT-3基础上进行优化和微调的版本，拥有比GPT-3更大的上下文理解能力和生成能力。GPT-3.5-Turbo在多个自然语言处理任务上表现出色，并且具有更快的响应速度。",
  },
  "gpt-3.5-turbo-0613": {
    max_tokens: 1000,
    tokenLen: 2000,
    production: "2023年6月13日gpt-3.5-turbo的快照，包含函数调用数据。与此不同的是，该型号不会收到更新，并且在新版本发布3个月后将被弃用。",
  },
  "gpt-3.5-turbo-16k": { max_tokens: 4000, tokenLen: 8000, production: "功能与标准模型gpt-3.5-turbo相同，但上下文是其 4 倍" },
  "gpt-3.5-turbo-16k-0613": {
    max_tokens: 4000,
    tokenLen: 8000,
    production: "2023年6月13日gpt-3.5-turbo-16k的快照。与此不同的是，该型号不会收到更新，并且在新版本发布3个月后将被弃用。",
  },
  "gpt-4": {
    max_tokens: 2000,
    tokenLen: 4000,
    production: "比任何GPT-3.5型号都更有能力，能够完成更复杂的任务，并针对聊天进行了优化。将在发布两周后更新我们的最新模型迭代。",
  },
  "gpt-4-0613": {
    max_tokens: 2000,
    tokenLen: 4000,
    production: "产品2023年6月13日的快照，带有函数调用数据。与此不同，该模型不会收到更新，并且在新版本发布3个月后将被弃用。gpt-4gpt-4”，",
  },
  "gpt-4-32k": {
    max_tokens: 8000,
    tokenLen: 16000,
    production: "与基本模式的功能相同，但上下文长度是基本模式的4倍。将使用我们的最新模型迭代进行更新。gpt-4",
  },
  "gpt-4-32k-0613": {
    max_tokens: 8000,
    tokenLen: 16000,
    production: "2023年6月13日的快照。与此不同的是，该型号不会收到更新，并且在新版本发布3个月后将被弃用。gpt-4-32gpt-4-32k",
  },
};
//复制文本
export function copyText(text: string) {
  try {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      unsecuredCopyToClipboard(text);
    }
    message.success("已复制", 1);
  } catch (error) {
    message.error(`复制失败:${error}`, 1);
  }
}
//非安全复制
function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
}

// 判断不同端
export const isMobile = () => {
  let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (h > w) {
    return true;
  } else {
    return false;
  }
};
/**
 * Resize a base 64 Image
 * @param {String} base64 - The base64 string (must include MIME type)
 * @param {Number} newWidth - The width of the image in pixels
 * @param {Number} newHeight - The height of the image in pixels
 */
export function resizeBase64Img(base64: string, newWidth: number, newHeight: number) {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    let context: any = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
}
