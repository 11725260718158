import { Button, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useRef, useState } from "react";
import { cloudflareGptTalkApi, gptTalkApi } from "../../utils/api";
import { AiBlock, HumanBlock, ModelSelect, RolePlay } from "./components";
import "./index.scss";

export default function Talk(props: { setTalkWait: any }) {
  let curdomHeight = 0; //当前文档流的实时高度
  const [curInput, setCurInput] = useState<string>(""); //输入框的内容
  const [talkBlocks, setTalkBlocks] = useState<any[]>([]); //所有对话的dom
  const [curAiBlock, setCurAiBlock] = useState<any>(null); //当前文档流的dom
  const messagesEnd = useRef<any>(null); //末端dom，用于滑到底部
  const curAiBlockRef = useCallback((node: any) => node?.getBoundingClientRect().height !== curdomHeight && scrollToBottom(), [curAiBlock]);
  const roleTypMap: any = { Ai: "assistant", Human: "user" };
  const [open, setOpen] = useState(false); //提示词弹窗
  const [modelOpen, setModelOpen] = useState(false); //模型弹窗
  const [curModel, setCurModel] = useState("gpt-3.5-turbo"); //当前模型
  const [requireType, setRequireType] = useState<boolean>(false); //是否用服务器
  const createCreative = useRef<any>(null);

  //提交内容
  const handleSubmit = async (e: any) => {
    if (e) {
      setCurInput(""); //清空内容
      let beforeBlocks = talkBlocks.map((item) => item);
      beforeBlocks.push({ type: "Human", value: e });
      setTalkBlocks(beforeBlocks);
      scrollToBottom();
    } else {
      message.info("请输入内容", 1);
    }
  };
  useEffect(() => {
    if (talkBlocks?.slice(-1)?.[0]?.type === "Human") {
      handleApi(talkBlocks);
    }
  }, [talkBlocks]);
  //访问api
  const handleApi = async (talkBlocks: any) => {
    let standardMessage = talkBlocks.map((talkBlock: any) => {
      return { role: roleTypMap[talkBlock.type], content: talkBlock.value };
    });
    props.setTalkWait("zby的土豆服务器拼命加载中");
    let beforeBlocks = talkBlocks.map((e: any) => e);
    setCurAiBlock(<Spin size="small" />);
    scrollToBottom();
    let result = await (requireType ? gptTalkApi(standardMessage, setCurAiBlock, curModel) : cloudflareGptTalkApi(standardMessage, setCurAiBlock, curModel));
    setCurAiBlock(null);

    beforeBlocks.push({ type: "Ai", value: result });
    setTalkBlocks(beforeBlocks);
    props.setTalkWait("");
  };
  //翻到底部
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }, 1);
  };

  return (
    <div className="contain" ref={createCreative}>
      <RolePlay createCreative={createCreative} setCurInput={setCurInput} setOpen={setOpen} open={open}></RolePlay>
      <ModelSelect createCreative={createCreative} setCurModel={setCurModel} setOpen={setModelOpen} open={modelOpen}></ModelSelect>
      <div className="mainTalk">
        {talkBlocks.map((anBlock) => {
          if (anBlock.type === "Human") {
            return <HumanBlock key={anBlock.value} value={anBlock.value} />;
          }
          if (anBlock.type === "Ai") {
            return <AiBlock key={anBlock.value} value={anBlock.value} />;
          }
        })}
        {curAiBlock !== null && <AiBlock ref={curAiBlockRef} value={curAiBlock} />}
        <div style={{ height: "1px" }} ref={messagesEnd}></div>
      </div>
      <div className="submitBar">
        <div className="subTools">
          <img
            className="Icon"
            title="角色扮演"
            onClick={() => {
              setOpen(true);
            }}
            src={require("../../assets/篮球.png")}
          />
          <img
            className="Icon"
            title={`${curModel}`}
            onClick={() => {
              setModelOpen(true);
            }}
            src={require("../../assets/工具.png")}
          />
          <img
            className="Icon"
            title="清空聊天"
            onClick={() => {
              message.success("所有对话删除成功", 1);
              setTalkBlocks([]);
            }}
            src={require("../../assets/删除.png")}
          />
          <img
            className="Icon"
            title="切换模式"
            onClick={() => {
              message.success(requireType ? "切换为反代模式" : "切换为服务器模式", 1);
              setRequireType((v) => !!!v);
            }}
            src={require(requireType ? "../../assets/服务器.png" : "../../assets/飞行.png")}
          />
        </div>
        <TextArea
          className="subInput"
          placeholder="请输入~"
          confirm-type="send"
          onInput={(e: any) => {
            setCurInput(e.target.value);
          }}
          value={curInput}
          onPressEnter={() => handleSubmit(curInput)}></TextArea>
        <Button className="subButton" type="primary" onClick={() => handleSubmit(curInput)}>
          发送
        </Button>
      </div>
    </div>
  );
}
